/*Main Content Styles*/
.app-main-content {
  display: flex;
  flex: 1;
  width: 100%;
}

.app-wrapper {
  display: flex;
  flex:1;
  flex-direction: column;
  padding: 30px;
  width: 100%;

  @media screen and (max-width: 575px) {
    padding: 20px;
  }
}
